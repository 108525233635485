import React from 'react';

import Carousel from 'components/Carousel/Carousel';

import { container } from './OffersCarousel.module.scss';

const OffersCarousel = ({ title = null, products }) => {
  if (!products || products.length === 0) {
    return null;
  }
  return (
    <div className={container}>
      {title}
      <Carousel products={products} />
    </div>
  );
};

export default OffersCarousel;
