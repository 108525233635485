import * as React from 'react';

function SvgMinotaurOnly(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 137.5 85.7"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill={props.fill || '#C5A557'}
        d="M68.8 68.8C30.8 68.8 0 37.9 0 0h6.1c0 34.6 28.1 62.7 62.7 62.7S131.5 34.6 131.5 0h6.1c-.1 37.9-30.9 68.8-68.8 68.8z"
      />
      <path
        fill={props.fill || '#C5A557'}
        d="M111.7 85.7h-6.1c0-20.3-16.5-36.8-36.8-36.8C48.5 48.9 32 65.4 32 85.7h-6.1c0-23.7 19.2-42.9 42.9-42.9 23.6 0 42.9 19.2 42.9 42.9z"
      />
    </svg>
  );
}

export default SvgMinotaurOnly;
