import React from 'react';

import * as styles from './Product.module.scss';

import Product from './Product';

const CarouselTile = ({ product }) => {
  return (
    <div className={styles.outerContainer}>
      <Product product={product} />
    </div>
  );
};

export default CarouselTile;
