import * as React from 'react';

function SvgQuality(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path
        fill={props.fill || '#000'}
        d="M510.362 215.571l-81.99-125.025a10 10 0 00-8.361-4.516H92.378c-3.363 0-6.5 1.69-8.35 4.499L1.906 215.181a9.997 9.997 0 00.572 12.46l41.625 47.527c3.639 4.155 9.955 4.573 14.109.935 4.154-3.639 4.572-9.956.934-14.109l-27.097-30.941H166.55L234.137 461.8l-76.375-87.205c-3.638-4.155-9.956-4.573-14.109-.935-4.154 3.639-4.572 9.956-.934 14.109l105.79 120.791a9.993 9.993 0 0015.043-.001l112.63-128.632c3.639-4.155 3.22-10.472-.936-14.109-4.156-3.639-10.473-3.22-14.109.936l-83.21 95.033 67.584-230.735h134.445l-62.183 71.02c-3.639 4.155-3.22 10.472.936 14.109a9.957 9.957 0 006.583 2.477 9.972 9.972 0 007.526-3.413l76.706-87.605a9.997 9.997 0 00.838-12.069zm-481.789-4.516l63.785-96.821 63.495 96.821H28.573zm82.319-105.026h126.623l-63.311 96.541-63.312-96.541zm145.138 8.234l63.475 96.792h-126.95l63.475-96.792zm0 351.14l-68.642-234.35h137.284l-68.642 234.35zm81.99-262.582l-63.475-96.792h126.952l-63.477 96.792zm18.515 8.234l63.476-96.792 63.474 96.792h-126.95z"
      />
      <path
        fill={props.fill || '#000'}
        d="M157.189 327.017c-25.793 0-46.778-20.985-46.778-46.778 0-5.522-4.477-9.999-9.999-9.999s-9.999 4.477-9.999 9.999c0 25.793-20.985 46.778-46.778 46.778-5.521 0-9.999 4.477-9.999 9.999s4.478 9.999 9.999 9.999c25.793 0 46.778 20.985 46.778 46.778 0 5.522 4.478 9.999 9.999 9.999s9.999-4.477 9.999-9.999c0-25.793 20.985-46.778 46.778-46.778 5.522 0 9.999-4.477 9.999-9.999s-4.477-9.999-9.999-9.999zm-56.777 31.66a67.301 67.301 0 00-21.66-21.661 67.291 67.291 0 0021.66-21.661 67.301 67.301 0 0021.66 21.661 67.301 67.301 0 00-21.66 21.661zM502.001 40.023c-16.54 0-29.997-13.457-29.997-29.997 0-5.522-4.478-9.999-9.999-9.999s-9.999 4.477-9.999 9.999c0 16.54-13.457 29.997-29.997 29.997-5.521 0-9.999 4.477-9.999 9.999s4.478 9.999 9.999 9.999c16.54 0 29.997 13.457 29.997 29.997 0 5.522 4.478 9.999 9.999 9.999s9.999-4.477 9.999-9.999c0-16.54 13.457-29.997 29.997-29.997 5.521 0 9.999-4.477 9.999-9.999s-4.478-9.999-9.999-9.999zm-39.996 20.029a50.434 50.434 0 00-10.03-10.03 50.434 50.434 0 0010.03-10.03 50.434 50.434 0 0010.03 10.03 50.382 50.382 0 00-10.03 10.03zm-59.222 275.319a10.028 10.028 0 00-7.068-2.93c-2.631 0-5.199 1.06-7.069 2.93-1.86 1.86-2.93 4.44-2.93 7.069s1.069 5.209 2.93 7.069a10.072 10.072 0 007.069 2.93c2.63 0 5.209-1.07 7.068-2.93 1.86-1.86 2.931-4.44 2.931-7.069s-1.07-5.209-2.931-7.069z"
      />
    </svg>
  );
}

export default SvgQuality;
