import React from 'react';
import SwiperCore, {
  Autoplay,
  Navigation,
  Keyboard,
  A11y,
  EffectFade,
} from 'swiper';
import 'swiper/components/a11y/a11y.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import CarouselTile from 'components/Product/CarouselTile';

import * as styles from './Carousel.module.scss';

import 'styles/overrides/swiper-carousel.scss';
import 'styles/overrides/swiper-navigation.scss';

SwiperCore.use([Autoplay, Navigation, Keyboard, A11y, EffectFade]);

const settings = {
  autoplay: {
    delay: 5000,
  },
  spaceBetween: 48,
  navigation: {
    nextEl: '.swiper-button-next-unique',
    prevEl: '.swiper-button-prev-unique',
  },
  keyboard: true,
  slidesPerView: 4,
  scrollbar: { draggable: true },
  breakpoints: {
    0: {
      slidesPerView: 1,
    },
    640: {
      slidesPerView: 2,
    },
    920: {
      slidesPerView: 3,
    },
    1168: {
      slidesPerView: 4,
    },
  },
  className: 'swiper-container-carousel',
};

const Carousel = ({ products }) => {
  if (!products || products.length === 0) {
    return null;
  }
  return (
    <div className={styles.carouselContainer}>
      <Swiper {...settings}>
        {products.map((product) => (
          <SwiperSlide key={product._id}>
            <CarouselTile product={product} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-button-prev-unique"></div>
      <div className="swiper-button-next-unique"></div>
    </div>
  );
};

export default Carousel;
